import { useCallback } from 'react'

import { TrashIcon } from '@/components/icons/TrashIcon'
import { Button } from '@/components/ui/button/Button'
import { EmptyState } from '@/features/index/components/EmptyState'
import { Notification } from '@/features/notification/components/Notification'
import { useDeleteNotifications } from '@/features/notification/hooks/useDeleteNotifications'
import { useViewerWithNotifications } from '@/features/notification/hooks/useViewerWithNotifications'

export const NotificationList = ({
  hideNotificationSidebar,
}: {
  hideNotificationSidebar: () => void
}) => {
  const { viewer } = useViewerWithNotifications()
  const deleteNotificationsMutation = useDeleteNotifications()

  const handleDelete = useCallback(
    (ids: string[]) => {
      deleteNotificationsMutation.mutate(ids)
    },
    [deleteNotificationsMutation]
  )

  const getDeleteHandler = useCallback(
    (id: string) => () => {
      handleDelete([id])
    },
    [handleDelete]
  )

  return (
    <div className="flex flex-1 flex-col gap-3 overflow-auto p-12">
      {viewer.notifications.length === 0 ? (
        <EmptyState title="No updates" subtitle="You have no new updates." />
      ) : (
        viewer.notifications.map((notification, index) => (
          <div
            key={index}
            className="rounded-2xl border border-film-faint bg-mono-paper-translucent p-3 shadow-sm"
            data-testid={`viewer-notification-${index}`}
          >
            <div className="flex gap-2" key={index}>
              <Notification
                notification={notification}
                onClick={hideNotificationSidebar}
              />
              <div className="ml-auto">
                <Button
                  className="relative size-11 w-11 rounded-full"
                  size="sm"
                  data-testid={`delete-button-${notification.id}`}
                  onClick={getDeleteHandler(notification.id)}
                >
                  <TrashIcon />
                </Button>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  )
}

NotificationList.displayName = 'NotificationList'

import { useSuspenseQuery } from '@tanstack/react-query'
import { RouteContext } from '@tanstack/react-router'

import { useApi } from '@/contexts/ApiProvider'

interface CreateQueryOptionParameters {
  api: RouteContext['api']
}

export const createViewerQueryOptions = ({
  api,
}: CreateQueryOptionParameters) => {
  return {
    queryFn: api.fetchViewer,
    queryKey: ['viewer'],
  }
}

export const useViewer = () => {
  const api = useApi()

  const queryOptions = createViewerQueryOptions({ api })

  const { data } = useSuspenseQuery(queryOptions)

  return { viewer: data.viewer }
}

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { useApi } from '@/contexts/ApiProvider'
import { FetchViewerWithNotificationsQuery } from '@/gql/generated/graphql'
import { logger } from '@/lib/logger'

interface UseDeleteNotificationProperties {
  ids: string[]
  onMutate?: () => void
  onError?: () => void
}

export const useDeleteNotifications = (
  properties?: UseDeleteNotificationProperties
) => {
  const api = useApi()

  const queryKey = ['viewerWithNotifications']
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: api.deleteNotifications,
    onMutate: async (ids) => {
      const previousState =
        queryClient.getQueryData<FetchViewerWithNotificationsQuery>(queryKey)

      queryClient.setQueryData<FetchViewerWithNotificationsQuery>(
        queryKey,
        (previousState) => {
          if (!previousState?.viewer) return

          const notifications = previousState?.viewer?.notifications?.filter(
            ({ id }) => !ids.includes(id)
          )

          return {
            viewer: {
              ...previousState.viewer,
              notifications,
            },
          }
        }
      )
      properties?.onMutate?.()

      return { previousState }
    },
    // Partition: Important to keep these definitions below onMutate property so the TS compiler infers context properly
    onError: (error, _, context) => {
      queryClient.setQueryData(queryKey, context?.previousState)
      logger.error(error)
      toast.error('Notification deletion failed!')
      properties?.onError?.()
    },
  })
}

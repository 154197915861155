import { IconComponent } from '@/lib/types'

export const CrossLargeIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        d="M5.59028 4.40967L4.40973 5.59022L8.82379 9.99995L4.40973 14.4097L5.59028 15.5902L10 11.1762L14.4097 15.5902L15.5903 14.4097L11.1762 9.99995L15.5903 5.59022L14.4097 4.40967L10 8.82373L5.59028 4.40967Z"
        fill="currentColor"
      />
    </svg>
  )
}

CrossLargeIcon.displayName = 'CrossLargeIcon'

import { Link } from '@tanstack/react-router'

import { Avatar } from '@/components/ui/avatar/Avatar'
import { ViewerNotification } from '@/features/notification/hooks/useNotificationToast'
import { ChatMessage } from '@/features/task/components/chat/ChatMessage'
import { useUsers } from '@/features/task/hooks/useUsers'
import { formatMessageTimestamp } from '@/lib/utils'

interface NotificationProperties {
  notification: ViewerNotification
  onClick: () => void
  hideTimestamp?: boolean
}

export const Notification = ({
  hideTimestamp,
  notification,
  onClick,
}: NotificationProperties) => {
  const readableTaskId = `TASK-${notification.task?.id.slice(-4)}`
  const { users } = useUsers()

  return (
    <>
      <div>
        <Avatar
          src={notification.sourceMessage?.author.avatarUrl}
          alt={notification.sourceMessage?.author.name}
          className="size-8 min-w-8 rounded-full"
        />
      </div>
      <div className="flex flex-col gap-2 truncate">
        <div className="flex items-center gap-2">
          <p className="text-sm-regular text-mono-ink-strong">
            {notification.sourceMessage?.author.name}
            <span className="text-mono-ink-subtle">
              {' mentioned you ' + `${notification.task?.id ? ' in' : ''} `}
            </span>
            {notification.task?.id && (
              <Link
                data-testid={`open-notification-task-${notification.task.id}`}
                className="-ml-0.5 p-1 hover:rounded-sm hover:border-film-subtle hover:bg-film-normal focus:rounded-sm focus:bg-sky-50 focus:outline focus:outline-sky-300"
                to={`/all-work/${notification.task.id}`}
                onClick={onClick}
              >
                {readableTaskId}
              </Link>
            )}
          </p>
          {!hideTimestamp && (
            <span className="truncate overflow-ellipsis text-xs-regular text-film-strongest">
              {formatMessageTimestamp(
                notification.sourceMessage?.createdAt ?? notification.createdAt
              )}
            </span>
          )}
        </div>
        <p className="w-fit min-w-1 whitespace-pre-wrap rounded-lg border bg-film-subtle p-3">
          {notification.sourceMessage ? (
            <ChatMessage
              message={notification.sourceMessage?.body.text ?? ''}
              users={users}
            />
          ) : (
            <span
              className="text-film-strongest"
              data-testid={`message-deleted`}
            >
              This message has been deleted
            </span>
          )}
        </p>
      </div>
    </>
  )
}

Notification.displayName = 'Notification'

import { useSuspenseQuery } from '@tanstack/react-query'
import { RouteContext, useParams } from '@tanstack/react-router'

import { useApi } from '@/contexts/ApiProvider'
import { TASK_ROUTE_PATH } from '@/features/task/utils/constants'

interface CreateQueryOptionParameters {
  api: RouteContext['api']
  taskId: string
}

const TASK_REFETCH_INTERVAL = 1000

export const createTaskQueryOptions = ({
  api,
  taskId,
}: CreateQueryOptionParameters) => {
  return {
    queryFn: async () => {
      const [task] = await api.fetchTasksById([taskId])

      if (!task) {
        throw new Error('Task not found!')
      }

      return task
    },
    queryKey: ['task', taskId],
    refetchInterval: TASK_REFETCH_INTERVAL,
  }
}

export const useTask = (id?: string) => {
  const api = useApi()
  const parameters = useParams({ from: TASK_ROUTE_PATH })

  const taskId = id ?? parameters.taskId
  const queryOptions = createTaskQueryOptions({ api, taskId })

  const { data } = useSuspenseQuery(queryOptions)

  return { task: data }
}

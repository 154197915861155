import { IconComponent } from '@/lib/types'

export const BellNotificationIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <g opacity="0.8">
        <path
          d="M14.25 2.00519C12.2656 2.00519 10.2786 2.7604 8.76823 4.27081L6.15104 6.89061L3.34635 7.85935C2.69531 8.08592 2.20573 8.63279 2.05208 9.30206V9.30467H2.04948C2.01563 9.4505 2 9.60154 2 9.74998C2 10.2734 2.20573 10.7838 2.58594 11.164L5.38021 13.9583C4.83854 14.5469 4.5 15.3255 4.5 16.1875C4.5 18.0078 5.99219 19.5 7.8125 19.5C8.67188 19.5 9.45313 19.1614 10.0417 18.6198L12.8359 21.414C13.2135 21.7943 13.7266 22 14.25 22C14.3984 22 14.5495 21.9818 14.6953 21.9505H14.6979C15.3698 21.7943 15.9167 21.3047 16.1406 20.6536L17.112 17.8489L19.7318 15.2318C22.75 12.2109 22.75 7.28904 19.7292 4.27081C18.2188 2.7604 16.2344 2.00519 14.25 2.00519ZM14.25 3.49477C15.849 3.49477 17.4453 4.10675 18.6693 5.33071C21.1172 7.77863 21.1172 11.7239 18.6693 14.1693L15.8073 17.0312L14.7214 20.164C14.6667 20.3255 14.5312 20.4479 14.362 20.487C14.3229 20.4948 14.2865 20.5 14.25 20.5C14.1172 20.5 13.9922 20.4505 13.8958 20.3541L3.64583 10.1041C3.55208 10.0078 3.5 9.88019 3.5 9.74998C3.5 9.71352 3.50521 9.67706 3.51302 9.638C3.55208 9.47133 3.67187 9.33331 3.83594 9.27863L6.96875 8.19529L9.83073 5.33071C11.0547 4.10675 12.651 3.49477 14.25 3.49477ZM6.4401 15.0182L8.98177 17.5599C8.66667 17.8333 8.26302 18 7.8125 18C6.80208 18 6 17.1979 6 16.1875C6 15.737 6.16667 15.3307 6.4401 15.0182Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

BellNotificationIcon.displayName = 'BellNotificationIcon'

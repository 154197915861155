import { FC } from 'react'

import { HelpIcon } from '@/components/layout/navigation/HelpIcon'
import { Tooltip } from '@/components/ui/tooltip/Tooltip'
import { cn } from '@/lib/utils'

import styles from '@/components/layout/navigation/NavigationItem.module.css'

const subject = 'Support Button'
const to = 'support@interloom.com'
const body = `Add a question or feedback below and we will process it within 1 working day.

- - - - - - - 

[Replace this with your message]

- - - - - - -

Browser information: ${navigator.userAgent}
Instance: ${globalThis.location.host}
version: ${import.meta.env.PUBLIC_VERSION ?? ''}`

const href = encodeURI(`mailto:${to}?subject=${subject}&body=${body}`)

export const FeedbackButton: FC = () => {
  return (
    <Tooltip content="Send message to Interloom support" side="left">
      <a
        data-testid="Feedback-button"
        href={href}
        className={cn('h-11 w-13.5 cursor-pointer', styles.NavItem)}
      >
        <HelpIcon />
      </a>
    </Tooltip>
  )
}

FeedbackButton.displayName = 'FeedbackButton'

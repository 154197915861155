import { useSuspenseQuery } from '@tanstack/react-query'
import { RouteContext } from '@tanstack/react-router'

import { useApi } from '@/contexts/ApiProvider'
import { NOTIFICATION_REFETCH_INTERVAL } from '@/features/notification/constants'

interface CreateQueryOptionParameters {
  api: RouteContext['api']
}

export const createViewerQueryOptions = ({
  api,
}: CreateQueryOptionParameters) => {
  return {
    queryFn: api.fetchViewerWithNotifications,
    queryKey: ['viewerWithNotifications'],
  }
}

export const useViewerWithNotifications = (isRefetchEnabled = false) => {
  const api = useApi()

  const queryOptions = createViewerQueryOptions({ api })

  const { data } = useSuspenseQuery({
    ...queryOptions,
    refetchInterval: isRefetchEnabled ? NOTIFICATION_REFETCH_INTERVAL : false,
  })

  return { viewer: data.viewer }
}

import { IconComponent } from '@/lib/types'

export const CrossMediumIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        d="M13.7434 5.40365C13.5221 5.41017 13.3137 5.50131 13.1618 5.65973L9.99994 8.82162L6.83805 5.65973C6.6818 5.49914 6.46695 5.408 6.24126 5.408C5.90272 5.408 5.59673 5.61416 5.46869 5.92883C5.34282 6.2435 5.41878 6.60374 5.66183 6.83812L8.82156 10L5.65966 13.1619C5.44265 13.3702 5.35584 13.6806 5.4318 13.9714C5.50775 14.2643 5.73562 14.4922 6.02859 14.5682C6.31938 14.6441 6.62971 14.5573 6.83805 14.3381L9.99994 11.1784L13.1618 14.3381C13.3702 14.5573 13.6805 14.6441 13.9713 14.5682C14.2643 14.4922 14.4921 14.2643 14.5681 13.9714C14.644 13.6806 14.5572 13.3702 14.338 13.1619L11.1783 10L14.338 6.83812C14.5876 6.60157 14.6636 6.23482 14.5312 5.91798C14.3988 5.60114 14.0863 5.39714 13.7434 5.40365Z"
        fill="currentColor"
      />
    </svg>
  )
}

CrossMediumIcon.displayName = 'CrossMediumIcon'

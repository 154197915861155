import { IconComponent } from '@/lib/types'

export const FallbackAvatarIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      fill="none"
      viewBox="0 0 44 44"
      {...properties}
    >
      <path
        style={{ fill: `var(--vector-icon-border, #78716C)` }}
        d="M0 0H44V44H0z"
      ></path>
      <path
        style={{ fill: `var(--vector-icon-fill, #FAFAF9)` }}
        d="M22 12c-2.753 0-5 2.247-5 5s2.247 5 5 5 5-2.247 5-5-2.247-5-5-5zm0 1.5c1.943 0 3.5 1.557 3.5 3.5s-1.557 3.5-3.5 3.5a3.488 3.488 0 01-3.5-3.5c0-1.943 1.557-3.5 3.5-3.5zM15.99 24A2 2 0 0014 25.99v.76c0 1.802 1.14 3.195 2.654 4.031C18.167 31.617 20.084 32 22 32c1.917 0 3.833-.383 5.346-1.219 1.292-.71 2.263-1.849 2.534-3.281H30v-1.51A2 2 0 0028.01 24H15.99zm0 1.5h12.02c.28 0 .49.21.49.49v.76c0 1.198-.672 2.055-1.878 2.719-1.205.664-2.914 1.031-4.622 1.031-1.708 0-3.417-.367-4.622-1.031-1.206-.664-1.878-1.521-1.878-2.719v-.76c0-.28.21-.49.49-.49z"
      ></path>
    </svg>
  )
}

FallbackAvatarIcon.displayName = 'FallbackAvatar'

import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { useRouter } from '@tanstack/react-router'
import { FC, useCallback } from 'react'

import { BellNotificationIcon } from '@/components/icons/BellNotificationIcon'
import { CrossLargeIcon } from '@/components/icons/CrossLargeIcon'
import { FeedbackButton } from '@/components/layout/navigation/FeedbackButton'
import { NavigationHorizontalDash } from '@/components/layout/navigation/NavigationHorizontalDash'
import { NavigationItem } from '@/components/layout/navigation/NavigationItem'
import { Avatar } from '@/components/ui/avatar/Avatar'
import { Button } from '@/components/ui/button/Button'
import * as PopoverPrimitive from '@/components/ui/popover/Popover'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet/sheet'
import { useOIDCAuth } from '@/contexts/OidcAuthContext'
import { NotificationList } from '@/features/notification/components/NotificationList'
import { useNotificationToast } from '@/features/notification/hooks/useNotificationToast'
import { useBoolean } from '@/lib/hooks/useBoolean'
import { useViewer } from '@/lib/hooks/useViewer'
import { ValidRoute } from '@/router'

export type NavigationOrder = Array<ValidRoute | '---'>

const NAVIGATION_ORDER: NavigationOrder = [
  '/',
  '/all-work',
  '---', // This inserts a horizontal dash <NavigationHorizontalDash />
]

export const Navigation: FC = () => {
  const router = useRouter()
  const { viewer } = useViewer()
  const { signOut } = useOIDCAuth()
  const userActionsPopoverControl = useBoolean(false)
  const handleSignOut = useCallback(() => {
    void signOut()
    userActionsPopoverControl.setFalse()
  }, [signOut, userActionsPopoverControl])
  const notificationSidebarControl = useBoolean(false)
  useNotificationToast(notificationSidebarControl.value)

  return (
    <nav className="relative z-20 flex min-h-full w-25 min-w-25 flex-col items-center justify-between bg-mono-field-border py-4">
      <div className="flex flex-col items-center gap-2">
        {NAVIGATION_ORDER.map((path, index) => {
          if (path === '---') {
            return <NavigationHorizontalDash key={`dash-${index}`} />
          }

          const route = router.routesByPath[path]
          const tooltipText = route.interloom?.tooltipText
          const NavigationIcon = route.interloom?.NavigationIcon

          if (!NavigationIcon) {
            throw new Error(
              `NavigationIcon not defined for the route "${path}"!`
            )
          }

          return (
            <NavigationItem
              data-testid={`NavigationItem-${path}`}
              to={path}
              key={route.id}
              tooltipText={tooltipText}
            >
              <NavigationIcon className="size-6" />
            </NavigationItem>
          )
        })}
      </div>
      <div className="flex flex-col items-center gap-2 overflow-hidden">
        <PopoverPrimitive.Popover
          onOpenChange={userActionsPopoverControl.setValue}
          open={userActionsPopoverControl.value}
        >
          <PopoverPrimitive.PopoverTrigger
            className="rounded-full hover:opacity-80 hover:shadow-md"
            data-testid="user-actions-trigger"
          >
            <Avatar
              className="size-10 rounded-full"
              alt={viewer.name}
              src={viewer.avatarUrl}
            />
          </PopoverPrimitive.PopoverTrigger>
          <PopoverPrimitive.PopoverPortal>
            <PopoverPrimitive.PopoverContent
              onPointerDownOutside={userActionsPopoverControl.setFalse}
              onEscapeKeyDown={userActionsPopoverControl.setFalse}
              side="top"
              useTriggerWidth={false}
              className="z-30 w-[30rem] max-w-[100vw]"
              innerContainerClassName="p-0"
              data-testid="user-actions-popover"
            >
              <div className="flex w-full justify-end p-4">
                <Button
                  variant="subtle"
                  size={'sm'}
                  onClick={userActionsPopoverControl.setFalse}
                  data-testid="user-actions-popover-cancel-button"
                >
                  <CrossLargeIcon className="size-4" />
                </Button>
              </div>
              <div className="h-[1px] w-full bg-film-normal" />
              <div className="flex flex-row items-center gap-2 p-4">
                <Avatar
                  className="size-10 w-full rounded-full"
                  alt={viewer.name}
                  src={viewer.avatarUrl}
                />
                <p className="text-xl font-bold text-mono-ink-strong">
                  {viewer.name}
                </p>
              </div>
              <div className="h-[1px] w-full bg-film-normal" />
              <div className="flex w-full justify-end p-4">
                <Button
                  variant="subtle"
                  size={'sm'}
                  onClick={handleSignOut}
                  data-testid="user-actions-popover-signout-button"
                >
                  Sign out
                </Button>
              </div>
            </PopoverPrimitive.PopoverContent>
          </PopoverPrimitive.PopoverPortal>
        </PopoverPrimitive.Popover>
        <Sheet
          open={notificationSidebarControl.value}
          onOpenChange={notificationSidebarControl.toggle}
        >
          <SheetTrigger asChild>
            <Button variant="subtle" data-testid="notifications-trigger">
              <BellNotificationIcon className="size-6" />
            </Button>
          </SheetTrigger>
          <SheetContent
            data-testid="notifications-dialog"
            className="flex flex-col"
          >
            <SheetHeader>
              <SheetTitle>Notifications</SheetTitle>
              <SheetDescription>
                <VisuallyHidden>User notifications</VisuallyHidden>
              </SheetDescription>
            </SheetHeader>
            <NotificationList
              hideNotificationSidebar={notificationSidebarControl.setFalse}
            />
          </SheetContent>
        </Sheet>
        <div>
          <FeedbackButton />
          <div
            className="pt-2 text-center text-xs opacity-30"
            title={import.meta.env.PUBLIC_GIT_TIMESTAMP}
          >
            v{import.meta.env.PUBLIC_VERSION}
          </div>
        </div>
      </div>
    </nav>
  )
}

Navigation.displayName = 'Navigation'

import { IconComponent } from '@/lib/types'

export const TrashIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        d="M9.99908 1.66699C8.53641 1.66699 7.32113 2.75857 7.11496 4.16699H4.26557C4.22868 4.16048 4.19396 4.15831 4.15706 4.15831C4.12668 4.15831 4.0963 4.16265 4.06592 4.16699H2.70741C2.48172 4.16482 2.27338 4.28201 2.15837 4.47732C2.04552 4.67263 2.04552 4.91135 2.15837 5.10666C2.27338 5.30198 2.48172 5.41916 2.70741 5.41699H3.59934L4.64752 16.2633C4.76036 17.4309 5.75429 18.3337 6.92833 18.3337H13.0698C14.2439 18.3337 15.2378 17.4331 15.3506 16.2633L16.3988 5.41699H17.2907C17.5164 5.41916 17.7248 5.30198 17.8398 5.10666C17.9526 4.91135 17.9526 4.67263 17.8398 4.47732C17.7248 4.28201 17.5164 4.16482 17.2907 4.16699H15.9344C15.8671 4.15614 15.7999 4.15614 15.7348 4.16699H12.8832C12.677 2.75857 11.4618 1.66699 9.99908 1.66699ZM9.99908 2.91699C10.7825 2.91699 11.4249 3.44434 11.6072 4.16699H8.39101C8.5733 3.44434 9.21566 2.91699 9.99908 2.91699ZM4.85368 5.41699H15.1445L14.105 16.1418C14.0529 16.6822 13.6102 17.0837 13.0698 17.0837H6.92833C6.38797 17.0837 5.94309 16.68 5.89101 16.1418L4.85368 5.41699ZM8.52989 7.49165C8.18701 7.49599 7.91141 7.78027 7.91574 8.12533V14.3753C7.91357 14.601 8.03076 14.8094 8.22607 14.9244C8.42139 15.0372 8.6601 15.0372 8.85542 14.9244C9.05073 14.8094 9.16791 14.601 9.16574 14.3753V8.12533C9.16791 7.95605 9.10281 7.79329 8.98345 7.67394C8.86193 7.55458 8.69916 7.48948 8.52989 7.49165ZM11.4466 7.49165C11.1037 7.49599 10.8281 7.78027 10.8324 8.12533V14.3753C10.8302 14.601 10.9474 14.8094 11.1427 14.9244C11.3381 15.0372 11.5768 15.0372 11.7721 14.9244C11.9674 14.8094 12.0846 14.601 12.0824 14.3753V8.12533C12.0846 7.95605 12.0195 7.79329 11.9001 7.67394C11.7786 7.55458 11.6158 7.48948 11.4466 7.49165Z"
        fill="currentColor"
      />
    </svg>
  )
}

TrashIcon.displayName = 'TrashIcon'

import { createFileRoute, Navigate } from '@tanstack/react-router'
import { useEffect, useRef } from 'react'

import { useOIDCAuth } from '@/contexts/OidcAuthContext'

const AuthCallback = () => {
  const { error, loading, signInCallback } = useOIDCAuth()
  const callbackInvoked = useRef(false)

  useEffect(() => {
    void signInCallback()
    callbackInvoked.current = true
  }, [signInCallback])

  if (!callbackInvoked.current) return

  if (loading)
    return (
      <div
        data-testid="auth-callback-loading"
        className="flex flex-1 items-center"
      >
        Loading
      </div>
    )

  if (error) return <Navigate to="/signin" />

  return <Navigate to="/" />
}

AuthCallback.displayName = 'AuthCallback'

export const Route = createFileRoute('/_auth/auth-callback')({
  component: AuthCallback,
})

import { Link } from '@tanstack/react-router'
import { ComponentProps, FC, PropsWithChildren } from 'react'

import { Tooltip } from '@/components/ui/tooltip/Tooltip'

import styles from '@/components/layout/navigation/NavigationItem.module.css'

interface Properties extends PropsWithChildren<ComponentProps<typeof Link>> {
  tooltipText?: string
}

export const NavigationItem: FC<Properties> = ({
  children,
  to,
  tooltipText,
  ...properties
}) => {
  return (
    <Tooltip
      content={tooltipText}
      side="left"
      data-testid={`NavigationItemTooltip-${to}`}
    >
      <div
        className="relative h-11 w-13.5"
        data-testid={`NavigationItemContainer-${to}`}
      >
        <Link className={styles.NavItem} to={to} {...properties}>
          <span>{children}</span>
          <span className={styles.ActiveIndicator} />
        </Link>
      </div>
    </Tooltip>
  )
}
NavigationItem.displayName = 'NavigationItem'

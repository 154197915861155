import { IconComponent } from '@/lib/types'

export const EmptyStateIcon: IconComponent = ({ ...properties }) => (
  <svg
    width="68"
    height="69"
    viewBox="0 0 68 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path
      d="M0 17.5C0 8.11116 7.61116 0.5 17 0.5H51C60.3888 0.5 68 8.11116 68 17.5V51.5C68 60.8888 60.3888 68.5 51 68.5H17C7.61116 68.5 0 60.8888 0 51.5V17.5Z"
      fill="currentColor"
      fillOpacity="0.03"
    />
    <path
      d="M22.5 16.5C18.9271 16.5 16 19.4271 16 23V46C16 49.5729 18.9271 52.5 22.5 52.5H45.5C49.0729 52.5 52 49.5729 52 46V23C52 19.4271 49.0729 16.5 45.5 16.5H22.5ZM22.5 19.5H45.5C47.4479 19.5 49 21.0521 49 23V36.5H39.9219C39.0938 36.5 38.4219 37.1719 38.4219 38C38.4219 39.6042 37.8438 40.6406 37.0052 41.375C36.1719 42.1094 35.026 42.5 34 42.5C32.974 42.5 31.8281 42.1094 30.9948 41.375C30.1563 40.6406 29.5781 39.6042 29.5781 38C29.5781 37.1719 28.9063 36.5 28.0781 36.5H19V23C19 21.0521 20.5521 19.5 22.5 19.5ZM19 39.5H26.8958C27.224 41.1771 27.9063 42.651 29.0052 43.625C30.4427 44.8906 32.2552 45.5 34 45.5C35.7448 45.5 37.5573 44.8906 38.9948 43.625C40.0938 42.651 40.776 41.1771 41.1042 39.5H49V46C49 47.9479 47.4479 49.5 45.5 49.5H22.5C20.5521 49.5 19 47.9479 19 46V39.5Z"
      fill="#78716C"
    />
  </svg>
)

EmptyStateIcon.displayName = 'EmptyStateIcon'

import { EmptyStateIcon } from '@/features/index/components/EmptyStateIcon'

export const EmptyState = ({
  subtitle,
  title,
}: {
  title: string
  subtitle: string
}) => {
  return (
    <div
      className="flex h-[290px] w-full flex-col items-center justify-center rounded-xl bg-film-subtle"
      data-testid="empty-state"
    >
      <EmptyStateIcon />
      <h2 className="text-xl font-medium text-mono-ink-subtle">{title}</h2>
      <p className="text-md font-medium text-film-strongest">{subtitle}</p>
    </div>
  )
}

EmptyState.displayName = 'EmptyState'

import { Link } from '@tanstack/react-router'
import { FC } from 'react'
import { ToastContentProps } from 'react-toastify'

import { Button } from '@/components/ui/button/Button'
import { Notification } from '@/features/notification/components/Notification'
import { ViewerNotification } from '@/features/notification/hooks/useNotificationToast'

interface NotificationToastProperties {
  notification: ViewerNotification
}

export const NotificationToast: FC<
  ToastContentProps<NotificationToastProperties>
> = ({ closeToast, data: { notification } }) => {
  return (
    <div
      className="flex flex-col gap-3"
      data-testid={`notification-toast-${notification.id}`}
    >
      <div className="flex gap-2">
        <Notification
          notification={notification}
          onClick={closeToast}
          hideTimestamp
        />
      </div>
      <div className="flex justify-end gap-2">
        <Button
          variant="naked"
          className="border-film-subtle"
          onClick={closeToast}
        >
          Dismiss
        </Button>
        {notification.task?.id && (
          <Button asChild className="text-mono-ink-strong">
            <Link to={`/all-work/${notification.task.id}`} onClick={closeToast}>
              Show
            </Link>
          </Button>
        )}
      </div>
    </div>
  )
}

NotificationToast.displayName = 'NotificationToast'

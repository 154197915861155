import { createFileRoute } from '@tanstack/react-router'
import { FC } from 'react'

import { HomeHouseStartIcon } from '@/components/icons/HomeHouseStartIcon'

export const Route = createFileRoute('/_private/')()

const HomeBreadcrumb: FC = () => {
  return (
    <div className="flex items-center gap-2 text-xs-regular text-mono-ink-subtle">
      <HomeHouseStartIcon className="size-4" />
      <p>My Tasks</p>
    </div>
  )
}

HomeBreadcrumb.displayName = 'HomeBreadcrumb'

Route.interloom = {
  Breadcrumb: HomeBreadcrumb,
  NavigationIcon: HomeHouseStartIcon,
  tooltipText: 'Home',
}
